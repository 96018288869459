@font-face {
  font-family: inter-regular;
  src: url("../inter-fontFamily/Inter-Regular.ttf");
  font-display: swap;
}
@font-face {
  font-family: inter-SemiBold;
  src: url("../inter-fontFamily/Inter-SemiBold.ttf");
  font-display: swap;
}
@font-face {
  font-family: inter-Medium;
  src: url("../inter-fontFamily/Inter-Medium.ttf");
  font-display: swap;
}
@font-face {
  font-family: inter-Bold;
  src: url("../inter-fontFamily/Inter-Bold.ttf");
  font-display: swap;
}
@font-face {
  font-family: Inter-ExtraBold;
  src: url("../inter-fontFamily/Inter-ExtraBold.ttf");
  font-display: swap;
}
.semibold {
  font-family: inter-SemiBold;
}
body {
  font-family: "Inter", sans-serif;
  background-image: url("../imgs/main-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  color: #000042;
}
p,
h4,
span {
  color: #000042;
}
.hover-btn {
  background-color: #000042;
  color: white;
  font-size: 12px;
  &:hover {
    background-color: white;
    color: #000042;
  }
}
.non-hover-btn {
  background-color: white;
  color: #000042;
  font-size: 12px;
  &:hover {
    background-color: #000042;
    color: white;
  }
}
.form-control {
  color: #000042 !important;
  &:focus {
    box-shadow: none;
  }
}
.top-img {
  background-image: url("../imgs/top-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.text-dark {
  color: #000042;
}
h3 {
  color: rgb(182, 106, 86) !important;
}
.span-color {
  color: rgb(139, 174, 199) !important;
}
.type-example {
  color: rgb(83, 104, 146);
}
.border-line {
  width: 55px;
  border-top: 1px solid #bbbbcc;
  transform: translateY(23px);
}

&::-webkit-input-placeholder {
  max-width: "330px";
  color: red;
}
&:-ms-input-placeholder {
  max-width: "330px";
  color: red;
}
&::placeholder {
  max-width: "330px";
  color: red;
}
